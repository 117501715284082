<template>
<div v-if="adReady">
  <b-container style="min-height: 800px">
    <b-row class="text-white py-5">
      <b-col>
        <h1>{{ad.configuration.title}}</h1>
        <h3>{{ad.configuration.subtitle}}</h3>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <b-card-group deck>
          <b-card title="Presentation" class="mb-4" style="max-width: 300px; min-width: 300px;">
            <b-card-text>Our proposal</b-card-text>
            <b-button :to="{ name: 'AppUTPresentation' }" variant="primary">Go</b-button>
          </b-card>
          <b-card title="Controls" class="mb-4" style="max-width: 300px; min-width: 300px;">
            <b-card-text>Reference controls</b-card-text>
            <b-button :to="{ name: 'AppUTControls' }" variant="primary">Go</b-button>
          </b-card>
          <b-card title="Timeline" class="mb-4" style="max-width: 300px; min-width: 300px;">
            <b-card-text>Project plan and activities</b-card-text>
            <b-button :to="{ name: 'AppUTTimeline' }" variant="primary">Go</b-button>
          </b-card>
          <b-card title="Diagram" class="mb-4" style="max-width: 300px; min-width: 300px;">
            <b-card-text>Controls and process mind-map</b-card-text>
            <b-button :to="{ name: 'AppUTDiagram' }" variant="primary">Go</b-button>
          </b-card>
          <b-card title="Export" class="mb-4" style="max-width: 300px; min-width: 300px;">
            <b-card-text>Get all content in a simple format</b-card-text>
            <b-button :to="{ name: 'AppUTExport' }" variant="primary">Go</b-button>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div><router-view></router-view></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>

export default {
  components: {
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    adReady: function () {
      if (this.ad.configuration) {
        return true
      }
      return false
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'UT app start', action: 'open UT app' })
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
